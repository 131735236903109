import React, { useContext } from "react"
import Layout from "../../components/layout"

import ThemeContext from "../../context/ThemeContext"
import { graphql } from "gatsby"
import GenericLandingContentWrapper from "../../components/text-helpers/generic-landing-content-wrapper"
import SEO from "../../components/seo"
import MainSearch from "../../components/main-search/main-search"
import { NonStickyBonus } from "../../services/preset-filters/casino-filters"
import localize from "../../components/localize"

const Content = ({ bonuses, locale }) => {

  return (
    <div>
      <MainSearch bonuses={bonuses} locale={locale} />
    </div>
  )
}

const NoSticky = ({ location, data, pageContext }) => {
  let site = useContext(ThemeContext)
  let { locale, alternateLocaleSlugs } = pageContext
  let bonuses = site.bonuses;
  bonuses = NonStickyBonus(bonuses);

  const pageData = data.sanityGenericLandingPage

  return (
    <Layout silo={data.sanitySilo} breadcrumbNameOverride={pageData.breadcrumbName} pageContext={pageContext} location={location}>
      <SEO location={location} alternateSlug={alternateLocaleSlugs}
        title={pageData && pageData.seoTitle}
        description={pageData && pageData.seoMeta}
        bonuses={bonuses}
      />
      <GenericLandingContentWrapper pageData={pageData} headerClassName={`gambleGeneralWrapper`} bodyClassName={`gambleGeneralWrapper`}>
        <Content bonuses={bonuses} locale={locale} />
      </GenericLandingContentWrapper>
    </Layout>
  )
}

export default localize(NoSticky)
export const pageQuery = graphql`
  query NoStickyLanding {
    sanitySilo (_id: {eq: "3aacbadb-2f40-40ba-bcb8-f6deeb25edb4"}){
        _id
        breadcrumbTrail {
            ...LocaleSiloTrails
        }
    }
    sanityGenericLandingPage(
      name: { eq: "no-sticky-landing" }
    ) {
      ...GenericLanding
    }
  }
`
